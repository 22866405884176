import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import HomePage from './HomePage.jsx';
import CheckPage from './CheckPage.jsx';

function App() {
    return (
      <div>
        <Route path="/" exact component={CheckPage} />
      </div>
    );
}

export default App;
