import React, { Component } from 'react';
import './App.css';
import './index.css';
import './HomePage.css';
import 'react-typist/dist/Typist.css';
import Motto from './Motto.jsx';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "Motto",
      time: 0
    };
  }

  render() {
    return (
      <div>
        <Motto/>
      </div>
    );
  }
}

export default HomePage;
