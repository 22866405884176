import React, { Component } from 'react';
import './App.css';
import './index.css';
import './HomePage.css';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

class Motto extends Component {
  render() {
    return (
      <div className="container mottoBox text-center">
        <h1></h1>
        <Typist avgTypingSpeed={45} stdTypingDelay={20} cursor={{ hideWhenDone: false, hideWhenDoneDelay: 0}}>
          <span id="motto"> I REALLY REALLY REALLY</span>
          <br />
          <span id="motto"> LOVE YOU, ELISE  &lt;3</span>
          <br />
          <br />
          <span id="motto"> Hope North Carolina is treatin ya well :)</span>
        </Typist>
      </div>
    );
  }
}

export default Motto;
