import React, { Component } from 'react';
import './App.css';
import './index.css';
import './HomePage.css';
import 'react-typist/dist/Typist.css';
import Motto from './Motto.jsx';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {  Modal, ModalHeader, ModalBody, Col} from 'reactstrap';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

class CheckPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        initials: '',
        dob: '',
        show: '',
        pokemon: '',
        email: '',
        message: '',
        modalMessage: 'Please fill out all of the fields',
        title: 'Error',
        formValid: false,
        showModal: false,
        showMessage: false,
    }
    this._onButtonClick = this._onButtonClick.bind(this);
  }

  _onButtonClick() {
      this.setState({
        showMessage: true,
      });
    }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    }, () => this.validateForm());
  }

  // Validates if all fields have been filled
  validateForm() {
    console.log(this.state.formValid)
    if (this.state.initials !== "EK" || this.state.dob !== "26" || this.state.show !== "Stranger Things" || this.state.pokemon !== "Snorlax") {
        this.setState({
          modalMessage: "Please fill out all the fields",
          title: "Error",
          formValid: false
        })
    } else {
      this.setState({
        formValid: true
      })
    }
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  // dismiss after modal in 1.2 seconds
  dismissModal() {
    setTimeout(() => {
      this.setState({
        showModal: false
      });
    }, 1200);
  }

  render() {
    return (
      <div className="outsidebox">
        {this.state.showMessage ?
             <Motto /> :
             null
          }
        <Form>
        <FormGroup row>
          <Col sm={10}>
            <Input type="initials" name="initials" placeholder="Initials" value={this.state.initials} onChange={(event) => this.handleUserInput(event)}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={10}>
            <Input type="dob" name="dob" placeholder="Day of birth" value={this.state.dob} onChange={(event) => this.handleUserInput(event)}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={10}>
            <Input type="show" name="show" placeholder="Last show we watched together" value={this.state.show} onChange={(event) => this.handleUserInput(event)}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={10}>
            <Input type="pokemon" name="pokemon" placeholder="Your pokemon nickname" value={this.state.pokemon} onChange={(event) => this.handleUserInput(event)}/>
          </Col>
        </FormGroup>
        {/* Submit only if form is Valid */}
        {this.state.formValid &&
          <Button id="submit-button" onClick={this._onButtonClick}>Submit</Button>
        }
        {!this.state.formValid &&
          <Button id="submit-button" onClick={() => this.showModal()}>Submit</Button>
        }
        <Modal isOpen={this.state.showModal} onOpened={() => this.dismissModal()}>
          <ModalHeader>{this.state.title}</ModalHeader>
          <ModalBody>{this.state.modalMessage}</ModalBody>
        </Modal>
      </Form>
    </div>
    );
  }
}

export default CheckPage;
